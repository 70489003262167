<template>
	<div class='h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Invested/CPF Assets</Navbar>
		<div class="wrapper h90 OverflowYAuto">
			<!-- 表格 -->
			<el-table :data="assetsTableData" style="width: 100%" :cell-style="{cursor: 'pointer'}">
				<el-table-column prop="name" label="Invested Assets">
				</el-table-column>
				<el-table-column prop="Amount" label="Amount" width="400">
					<template slot-scope="scope">
						<span v-if="disabled">{{scope.row.AmountShow}}</span>
						<AmountInput v-else :inputValue="scope.row.AmountShow" :currentRow="scope.row" :currentArr="assetsTableData" @updateArr="updateArr"></AmountInput>
					</template>
				</el-table-column>
				<el-table-column label="" width="40" />
			</el-table>
			<!-- 其他资产 -->
			<Navbar backgroundColor="#e8e8e8" fontSize="18px" className="flex">
				Property (Investment)
				<i class="el-icon-plus addIcon" @click="propertyAddRow" v-if="!disabled"></i>
			</Navbar>
			<el-table :data="propertyTableData" style="width: 100%" :cell-style="{cursor: 'pointer'}" :show-header="false">
				<el-table-column prop="field_key" label="Name">
					<template slot-scope="scope">
						<span v-if="disabled">{{scope.row.field_key}}</span>
						<el-input v-else v-model="scope.row.field_key" placeholder="Name"  @input="scope.row.field_key = $util.inputReg(scope.row.field_key)"></el-input>
					</template>
				</el-table-column>
				<el-table-column prop="field_value" label="Amount" width="400">
					<template slot-scope="scope">
						<span v-if="disabled">{{scope.row.field_value_show}}</span>
						<AmountInputCustom v-else :inputValue="scope.row.field_value_show" :currentRow="scope.row" :currentArr="propertyTableData" @updateArr="updateArr2"></AmountInputCustom>
					</template>
				</el-table-column>
				<el-table-column width="40">
					<template slot-scope="scope">
						<i class="el-icon-delete" @click="propertyDeleteRow(scope.row.dynamic_field_order)" v-if="!disabled"></i>
					</template>
				</el-table-column>
			</el-table>
			<Navbar backgroundColor="#e8e8e8" fontSize="18px" className="flex">
				Others
				<i class="el-icon-plus addIcon" @click="investedAddRow" v-if="!disabled"></i>
			</Navbar>
			<el-table :data="investedTableData" style="width: 100%" :cell-style="{cursor: 'pointer'}" :show-header="false">
				<el-table-column prop="field_key" label="Name">
					<template slot-scope="scope">
						<span v-if="disabled">{{scope.row.field_key}}</span>
						<el-input v-else v-model="scope.row.field_key" placeholder="Name"  @input="scope.row.field_key = $util.inputReg(scope.row.field_key)"></el-input>
					</template>
				</el-table-column>
				<el-table-column prop="field_value" label="Amount" width="400">
					<template slot-scope="scope">
						<span v-if="disabled">{{scope.row.field_value_show}}</span>
						<AmountInputCustom v-else :inputValue="scope.row.field_value_show" :currentRow="scope.row" :currentArr="investedTableData" @updateArr="updateArr3"></AmountInputCustom>
					</template>
				</el-table-column>
				<el-table-column width="40">
					<template slot-scope="scope">
						<i class="el-icon-delete" @click="investedDeleteRow(scope.row.dynamic_field_order)" v-if="!disabled"></i>
					</template>
				</el-table-column>
			</el-table>
			<!-- 总资产 -->
			<div class="ml60 mt20px mb20px" style="color: #606266;">Total S$<span style="color: black;">{{totalAmount}}</span></div>
		</div>
	</div>
</template>

<script>
import Navbar from "../../../../components/Navbar.vue";
import AmountInput from "../../components/amountInput.vue";
import AmountInputCustom from "../../components/amountInputCustom.vue";
export default {
	components: { Navbar, AmountInput, AmountInputCustom },
	data () {
		return {
			assetsTableData: [
				{
					Amount: '0',
					name: 'Shares',
					value: 'shares',
				}, {
					Amount: '0',
					name: 'Bond',
					value: 'bond',
				}, {
					Amount: '0',
					name: 'Unit trust',
					value: 'unit_trust',
				}, {
					Amount: '0',
					name: 'ILP',
					value: 'ilp',
				}, {
					Amount: '0',
					name: 'Insurance cash value (e.g. Traditional policies)',
					value: 'insurance_cash_value',
				}, {
					Amount: '0',
					name: 'CPF Ordinary Account',
					value: 'cpf_ordinary_account',
				}, {
					Amount: '0',
					name: 'CPF Special Account',
					value: 'cpf_specia_account',
				}, {
					Amount: '0',
					name: 'CPF Medisave Account',
					value: 'cpf_medisave_account',
				}, {
					Amount: '0',
					name: 'SRS Account',
					value: 'srs_account',
				}, {
					Amount: '0',
					name: 'Retirement Account',
					value: 'retirement_account',
				}, {
					Amount: '0',
					name: 'Business Interest',
					value: 'business_interestabc',
				},
			],
			propertyTableData: [],
			investedTableData: [],
			disabled: false,
			kycAllData: {}
		};
	},
	computed: {
		total_invested_assets () {
			let total = 0
			this.assetsTableData.map(res => {
				total += parseFloat(res.Amount || 0)
			})
			this.propertyTableData.map(res => {
				if (res.field_key) total += parseFloat(res.field_value)

			})
			this.investedTableData.map(res => {
				if (res.field_key) total += parseFloat(res.field_value)
			})
			if (isNaN(total)) total = ''
			return (Math.round(total * 100) / 100).toFixed(10).replace(/(0+)$/g, '')
		},
		totalAmount () {
			return this.$formatMoney((Math.round(this.total_invested_assets * 100) / 100).toFixed(2))
		},
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {},
	methods: {
		updateArr (arr) {
			this.$forceUpdate()
			this.assetsTableData = arr
		},
		updateArr2 (arr) {
			this.$forceUpdate()
			this.propertyTableData = arr
		},
		updateArr3 (arr) {
			this.$forceUpdate()
			this.investedTableData = arr
		},
		propertyAddRow () {
			this.propertyTableData.push({
				index: this.propertyTableData.length,
				field_value_show: '',
				field_key: '',
				field_value: '',
				dynamic_field_type: 'property',
				dynamic_field_order: Date.now().toString()
			})
		},
		propertyDeleteRow (index) {
			this.propertyTableData = this.propertyTableData.filter(n => n.dynamic_field_order !== index)
		},
		investedAddRow () {
			this.investedTableData.push({
				index: this.investedTableData.length,
				field_value_show: '',
				field_key: '',
				field_value: '',
				dynamic_field_type: 'property',
				dynamic_field_order: Date.now().toString()
			})
		},
		investedDeleteRow (index) {
			this.investedTableData = this.investedTableData.filter(n => n.dynamic_field_order !== index)
		},
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
			if (!this.disabled) {
				// assetsTableData值赋予kycAllData
				this.assetsTableData.map(res => { this.kycAllData.kyc.client[this.kycClient].assets_liabilities[res.value] = res.Amount })
				// total
				this.kycAllData.kyc.client[this.kycClient].assets_liabilities.total_invested_assets = this.total_invested_assets
				this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
				// 自定义内容存在vuex中，在父页面点击下一步时，合并所有自定义内容,去掉key为空的
				this.propertyTableData = this.propertyTableData.filter(n => n.field_key !== '')
				this.investedTableData = this.investedTableData.filter(n => n.field_key !== '')
				this.$store.commit('SET_ASSETS_LIABILITIES', { property: this.propertyTableData })
				this.$store.commit('SET_ASSETS_LIABILITIES', { invested: this.investedTableData })
			}
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		// 若有值 则赋值
		this.assetsTableData.map((key, i) => {
			key.Amount = this.kycAllData.kyc.client[this.kycClient].assets_liabilities[key.value]
			key.AmountShow = 'S$ ' + this.$formatMoney((Math.round(key.Amount * 100) / 100))
			key.index = i
		})
		this.propertyTableData = this.$store.state.KYC.Assets_liabilities['property']
		this.propertyTableData.map((key, i) => {
			key.field_value_show = 'S$ ' + this.$formatMoney((Math.round(key.field_value * 100) / 100))
			key.index = i
		})
		this.investedTableData = this.$store.state.KYC.Assets_liabilities['invested']
		this.investedTableData.map((key, i) => {
			key.field_value_show = 'S$ ' + this.$formatMoney((Math.round(key.field_value * 100) / 100))
			key.index = i
		})
		this.disabled = this.$router.currentRoute.fullPath.includes('View')
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>
<style lang='css' scoped>
span {
	line-height: 24px;
}
</style>